import * as React from "react";
import { Layout } from "../components";
import PageNotFoundContainer from '../sections/misc/404/PageNotFoundContainer';

const NotFound = () => {
  return (
    <Layout>
      <PageNotFoundContainer />
    </Layout>
  );
};

export default NotFound;
